// Generated by Framer (400c93f)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-7Qgve"

const variantClassNames = {wU9JPioIr: "framer-v-169g3he"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "wU9JPioIr", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{hash: ":E5xbXrjqj", webPageId: "augiA20Il"}} motionChild nodeId={"wU9JPioIr"} openInNewTab={false} scopeId={"KuUrcl5x_"} smoothScroll><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 258, pixelWidth: 679, positionX: "left", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/qzUftORqSTOx4VX8CfYLDtOzJGY.png", srcSet: "https://framerusercontent.com/images/qzUftORqSTOx4VX8CfYLDtOzJGY.png?scale-down-to=512 512w,https://framerusercontent.com/images/qzUftORqSTOx4VX8CfYLDtOzJGY.png 679w"}} className={`${cx(scopingClassNames, "framer-169g3he", className, classNames)} framer-11n6r7`} data-framer-name={"primary"} layoutDependency={layoutDependency} layoutId={"wU9JPioIr"} ref={refBinding} style={{...style}}/></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7Qgve.framer-11n6r7, .framer-7Qgve .framer-11n6r7 { display: block; }", ".framer-7Qgve.framer-169g3he { height: 39px; overflow: visible; position: relative; text-decoration: none; width: 103px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39
 * @framerIntrinsicWidth 103
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerKuUrcl5x_: React.ComponentType<Props> = withCSS(Component, css, "framer-7Qgve") as typeof Component;
export default FramerKuUrcl5x_;

FramerKuUrcl5x_.displayName = "logo";

FramerKuUrcl5x_.defaultProps = {height: 39, width: 103};

addFonts(FramerKuUrcl5x_, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})